<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.new_m", {
                m: $t("message.reasontype"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-form-item
                  :label="$t('message.nameGiven')"
                  prop="name"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.name"
                    v-model="form.name"
                    :placeholder="$t('message.nameGiven')"
                  ></crm-input>
                </el-form-item>
              </el-form>
            </el-col>
            <!-- end col -->
          </el-row>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawer],
  name: "ReasonTypeController",
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "reasonType/rules",
      model: "reasonType/model",
      columns: "reasonType/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "reasonType/store",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>

